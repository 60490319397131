import React from "react"
import Recipes from "../templates/Inspirations"
import { graphql } from "gatsby"
import PostListPage from "../components/commons/post-list-page"

const Inspirações = ({ data }) => {
  const options = {
    title: 'Inspirações',
    titleColor: "#D7C0CF",
    titleBg: "#789A78",
  }

  return (
    <PostListPage options={options}>
      <Recipes data={data.allWpInspiration} />
    </PostListPage>
  )
}

export default Inspirações

export const query = graphql`
  query Inspirations {
    allWpInspiration(limit: 10) {
      edges {
        node {
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          title
          slug
          link
        }
      }
    }
  }
`
